import React, { useEffect, useRef } from 'react'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import ReportFilterComponent from '../report-filter.form.component'
import { useTranslation } from 'react-i18next'
import { useBudgetAnalysisContext } from '../../../contexts/reports/report.context'
import { budgetAnalysisTableColumns } from '../../../components/reports/budget-analysis-table.column'
import { IBudgetAnalysis } from '../../../models/reports/budget-analysis.model'
import { Table } from 'antd'
import { BUDGET_ANALYSIS } from '../../../constants/configurations.constant'
import ReportWrapper from '../../../components/reports/report-wrapper.component'

const SurgeBASiteReport: React.FC = () => {
    const {
        hasReportData,
        reportData,
        setBudgetAnalysisType,
        setHasReportData,
        setHasPdf,
    } = useBudgetAnalysisContext()
    const { t } = useTranslation()
    const ref = useRef()
    setBudgetAnalysisType(BUDGET_ANALYSIS.SITE)

    useEffect(() => {
        setHasReportData(false)
        setHasPdf(false)
    }, [])
    return (
        <div>
            <TitleBar title={t('common:siteReport.interventionMainPage.title')} />
            <ReportFilterComponent isSurge formRef={ref} />
            <ReportWrapper />
            {hasReportData && (
                <div style={{ marginTop: '1rem' }}>
                    {/* <ExcelExportWrapper columns={budgetAnalysisTableColumns} data={reportData} fileName="budget analysis by cost category">*/}
                    <Table<IBudgetAnalysis>
                        dataSource={reportData}
                        columns={budgetAnalysisTableColumns}
                        size={'small'}
                        rowKey={'code'}
                        scroll={{ x: '500' }}
                        pagination={false}
                    />
                    {/* </ExcelExportWrapper> */}
                </div>
            )}
        </div>
    )
}
export default SurgeBASiteReport
