import { apiRequests } from '..'
import {
    IBudgetDashboard,
    IBudgetDashboardResponse,
    IBudgetSummaryOptions,
} from '../../models/budgeting/budget-dashboard.model'

const budgetDashboardService = {
    details: (options: IBudgetSummaryOptions): Promise<IBudgetDashboard> =>
        apiRequests.get(
            `/budgetDashboard/?project=${options.project}&component=${options.component}&period=${options.period}&region=${options.region}`
        ),
}

export default budgetDashboardService
