export const appRoutes = {
    HOME: '/',
    DASHBOARD: '/dashboard',

    // runner period
    RUNNER: {
        // runners
        RUNNERS: '/runners',
        RUNNER_DETAILS: '/runners/:code',
        RUNNER_PERIOD: '/runner-periods',
        RUNNER_PERIOD_DETAILS: '/runner-periods/:code',
    },

    MAIN_ACTIVITY: {
        // activities
        ACTIVITY_BATCH_SUMMARY: '/site-activities',
        ACTIVITY_BATCH: '/site-activities/:batch',
        ACTIVITY_BATCH_DETAILS: '/site-activities/:batch/:batchLine',

        PROGRAM_MANAGEMENT_DHS: '/program-management-dhs',
        PROGRAM_MANAGEMENT_MVAN: '/program-management-mvan',
        PROGRAM_MANAGEMENT_BO: '/program-management-bo',
        // Milestone
        MILESTONE_PROJECTION: '/milestone-projections',
        MILESTONE_ACHIEVEMENT: '/milestone-achievements',

        // regional modification
        HIV_REGIONAL_MODIFICATION: '/hiv-regional-modifications',
        HIV_ZONE_SITE_MODIFICATION: '/hiv-zone-site-modifications',
        HIV_ZONE_ABOVE_SITE_MODIFICATION: '/hiv-zone-above-site-modifications',
    },

    LABORATORY_ACTIVITY: {
        // activities
        LABORATORY_ACTIVITY_BATCH_SUMMARY: '/laboratory-activities',
        LABORATORY_ACTIVITY_BATCH: '/laboratory-activities/:batch',
        LABORATORY_ACTIVITY_BATCH_DETAILS:
            '/laboratory-activities/:batch/:batchLine',

        LABORATORY_PROGRAM_MANAGEMENT_DHS: '/laboratory-program-management-dhs',
        LABORATORY_PROGRAM_MANAGEMENT_MVAN: '/laboratory-program-management-mvan',
        LABORATORY_PROGRAM_MANAGEMENT_BO: '/laboratory-program-management-bo',
        // Milestone
        LABORATORY_MILESTONE_PROJECTION: '/laboratory-milestone-projections',
        LABORATORY_MILESTONE_ACHIEVEMENT: '/laboratory-milestone-achievements',
        // lab regional modification
        LAB_REGIONAL_MODIFICATION: '/lab-regional-modifications',
        LAB_ZONE_SITE_MODIFICATION: '/lab-zone-site-modifications',
        LAB_ZONE_ABOVE_SITE_MODIFICATION: '/lab-zone-above-site-modifications',
    },

    SURGE_ACTIVITY: {
        // activities
        SURGE_ACTIVITY_BATCH_SUMMARY: '/surge-activities',
        SURGE_ACTIVITY_BATCH: '/surge-activities/:batch',
        SURGE_ACTIVITY_BATCH_DETAILS: '/surge-activities/:batch/:batchLine',

        SURGE_PROGRAM_MANAGEMENT_DHS: '/surge-program-management-dhs',
        SURGE_PROGRAM_MANAGEMENT_MVAN: '/surge-program-management-mvan',
        SURGE_PROGRAM_MANAGEMENT_BO: '/surge-program-management-bo',
        // Milestone
        SURGE_MILESTONE_PROJECTION: '/surge-milestone-projections',
        SURGE_MILESTONE_ACHIEVEMENT: '/surge-milestone-achievements',
        // lab regional modification
        SURGE_REGIONAL_MODIFICATION: '/surge-regional-modifications',
        SURGE_ZONE_SITE_MODIFICATION: '/surge-zone-site-modifications',
        SURGE_ZONE_ABOVE_SITE_MODIFICATION: '/surge-zone-above-site-modifications',
    },

    SURGE_REPORTS: {
        BA_COST_CATEGORY: '/surge-reports/budget-analysis-by-cost-category',
    },

    // site reports
    SITE_REPORT: {
        // other reports
        BA_COST_CATEGORY: '/site-reports/budget-analysis-by-cost-category',
        BA_INTERVENTION: '/site-reports/budget-analysis-by-intervention',
        BA_SITE: '/site-reports/budget-analysis-by-site',
        BUDGET_EXECUTION: '/site-reports/budget-execution',
        CDV_ACC_TEMPLATE: '/site-reports/cdv-acc-template',
        CDV_ER_TEMPLATE: '/site-reports/cdv-er-template',
        COSTED_WORK_PLAN: '/site-reports/costed-work-plan',
    },
    PROGRAM_REPORT: {
        BA_COST_CATEGORY: '/program-reports/budget-analysis-by-cost-category',
        BA_INTERVENTION: '/program-reports/budget-analysis-by-intervention',
        BA_SITE: '/program-reports/budget-analysis-by-site',
        BUDGET_EXECUTION: '/program-reports/budget-execution',
        COSTED_WORK_PLAN: '/program-reports/costed-work-plan',
    },
    ZONE_REPORT: {
        BA_COST_CATEGORY: '/zone-reports/budget-analysis-by-cost-category',
        BA_APPROACH: '/zone-reports/budget-analysis-by-approach',
        BA_INTERVENTION: '/zone-reports/budget-analysis-by-intervention',
        BA_SITE: '/zone-reports/budget-analysis-by-site',
    },
    ACTIVITY_HISTORY: {
        BATCH_DETAIL: '/regional-modifications/batch-summary/:code',
        REGIONAL_MODIFICATION_SUMMARY:
            '/regional-modifications/batch-summary/:batch/:batchLine',
    },
    // MODIFICATIONS: {
    //     REGION: '/regional-modifications',
    //     ZONE_SITE: '/zone-site-modifications',
    //     ZONE_ABOVE_SITE: '/zone-above-site-modification',
    // },
    SETTINGS: {
        USERS: '/users',
        ROLE_MANAGEMENT: '/role-management',
        CLAIMS: '/claims',
        USER_COORDINATION: '/user-coordination',
    },

    FAQs: {
        TICKET: '/tickets',
        TICKET_DETAILS: '/tickets/:code',
        TICKET_STATUS: '/ticket-status',
        TICKET_STATUS_DETAILS: '/ticket-status/:code',
    },
}
