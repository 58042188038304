import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as budgetDashboardActions from '../../redux/budgeting/budget-dashboard.slice'
import { IBudgetDashboard } from '../../models/budgeting/budget-dashboard.model'
import { UpdateMode } from '../../models/update-mode.enum'
import { useCurrentBranch } from '../common/current-branch/current-branch.hook'
import { useRunner } from './runner.hook'
import { useRunnerComponent } from './runner-component.hook'
const useBudgetDashboard = () => {
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.budgetDashboard.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.budgetDashboard.initialFetch
    )
    const budgetDashboard = useSelector<rootState, IBudgetDashboard>(
        (state) => state.budgetDashboard.budgetDashboard
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.budgetDashboard.updateMode
    )
    const { currentBranch } = useCurrentBranch()
    const { runnerComponent } = useRunnerComponent()

    const {
        annualBudgets,
        componentBudgets,
        regionExpenditures,
        totalExpenditures,
        interventions,
        costCategories,
    } = budgetDashboard

    const dispatch = useDispatch()

    const loadBudgetDashboards = useCallback(() => {
        if (initialFetch) {
            dispatch(
                budgetDashboardActions.fetchBudgetDashboardAsync({
                    project: currentBranch.project,
                    component: runnerComponent.component,
                    period: 'week',
                    region: currentBranch.region,
                })
            )
        }
    }, [dispatch, initialFetch])

    const reloadBudgetDashboards = useCallback(
        (period: string) => {
            console.log('currentBranch', currentBranch)
            dispatch(
                budgetDashboardActions.fetchBudgetDashboardAsync({
                    project: currentBranch.project,
                    component: runnerComponent.component,
                    period: period,
                    region: currentBranch.region,
                })
            )
        },
        [dispatch]
    )

    useEffect(() => {
        loadBudgetDashboards()
    }, [budgetDashboard, isLoading, initialFetch, loadBudgetDashboards])

    return {
        annualBudgets,
        componentBudgets,
        regionExpenditures,
        totalExpenditures,
        reloadBudgetDashboards,
        interventions,
        costCategories,
        budgetDashboard,
        isLoading,
        initialFetch,
        updateMode,
    }
}

export { useBudgetDashboard }
