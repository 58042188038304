import React from 'react'

import './home.styles.scss'

import HomeDashboardQuickinfo from './home-dashboard-quickinfo.component'
import HomeDashboardFilter from './home-dashboard.filter.component'
import HomeDashboardBudgetIntervention from './home-dashboard-budget.component'
import HomeDashboardBudgetBudgetCode from './home-dashboard-budget-code.component'
import BudgetPieChartDashboard from './budget-chart.component'
import { useBudgetDashboard } from '../../hooks/budgeting/budget-dashboard.hook'

const HomePage: React.FunctionComponent = () => {
    const {
        annualBudgets,
        componentBudgets,
        regionExpenditures,
        totalExpenditures,
        interventions,
        costCategories,
    } = useBudgetDashboard()

    return (
        <div>
            <HomeDashboardFilter
                filterField="last"
                options={[
                    { value: 'week', label: 'Week to Date' },
                    { value: 'month', label: 'Month to Date' },
                    { value: 'component', label: 'Component to Date' },
                ]}
            />
            <HomeDashboardQuickinfo
                annualBudgets={annualBudgets}
                componentBudgets={componentBudgets}
                regionExpenditures={regionExpenditures}
                totalExpenditures={totalExpenditures}
            />
            <HomeDashboardBudgetBudgetCode
                annualBudgets={annualBudgets}
                componentBudgets={componentBudgets}
                regionExpenditures={regionExpenditures}
                totalExpenditures={totalExpenditures}
            />
            <BudgetPieChartDashboard
                budget={annualBudgets}
                expenditure={totalExpenditures}
            />
            <HomeDashboardBudgetIntervention
                interventions={interventions}
                costCategories={costCategories}
            />
        </div>
    )
}

export default HomePage
