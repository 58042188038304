import { activityReducer } from './activity.slice'
import { activityPlanReducer } from './activity-plan.slice'
import { budgetCodeReducer } from './budget-code.slice'
import { budgetHistoryReducer } from './budget-history.slice'
import { budgetReducer } from './budget.slice'
import { componentReducer } from './component.slice'
import { copYearBudgetCodeReducer } from './cop-year-budget-code.slice'
import { copYearCostCategoryReducer } from './cop-year-cost-category.slice'
import { copYearInterventionReducer } from './cop-year-intervention.slice'
import { copYearReducer } from './cop-year.slice'
import { costCategoryReducer } from './cost-category.slice'
import { districtReducer } from './district.slice'
import { includeRunnerReducer } from './include-runner.slice'
import { interventionReducer } from './intervention.slice'
import { milestoneReducer } from './milestone.slice'
import { projectReducer } from './project.slice'
import { quarterReducer } from './quarter.slice'
import { regionRunnerPeriodReducer } from './region-runner-period.slice'
import { runnerComponentHistoryReducer } from './runner-component-history.slice'
import { runnerComponentReducer } from './runner-component.slice'
import { runnerHistoryReducer } from './runner-history.slice'
import { runnerPeriodComponentReducer } from './runner-period-component.slice'
import { runnerPeriodHistoryReducer } from './runner-period-history.slice'
import { runnerPeriodStatusReducer } from './runner-period-status.slice'
import { runnerPeriodReducer } from './runner-period.slice'
import { runnerStatusReducer } from './runner-status.slice'
import { runnerReducer } from './runner.slice'
import { siteTypeReducer } from './site-type.slice'
import { siteReducer } from './site.slice'
import { zoneReducer } from './zone.slice'
import { transactionCodeReducer } from './transaction-code.slice'
import { userCoordinationReducer } from './user-coordination.slice'
import { regionReducer } from './region.slice'
import { copYearSiteReducer } from './cop-year-site.slice'
import { targetReducer } from './target.slice'
import { outlayReducer } from './outlay.slice'
import { annualTargetReducer } from './annual-target.slice'
import { copYearOutlayReducer } from './cop-year-outlay.slice'
import { strategyReducer } from './strategy.slice'
import { weekReducer } from './week.slice'
import { outlayDashReducer } from './outlay-dash.slice'
import { projectSiteReducer } from './project-site.slice'
import { milestoneDashReducer } from './milestone-dash.slice'
import { dailyDataReducer } from './daily-data.slice'
import { runnerArchiveReducer } from './runner-archive.slice'
import { surgeActivityReducer } from './surge-activity.slice'
import { budgetDashboardReducer } from './budget-dashboard.slice'

const reducers = {
    activity: activityReducer,
    activityPlan: activityPlanReducer,
    budgetCode: budgetCodeReducer,
    budgetDashboard: budgetDashboardReducer,
    budgetHistory: budgetHistoryReducer,
    budgetCodeHistory: budgetHistoryReducer,
    budget: budgetReducer,
    component: componentReducer,
    copYearBudgetCode: copYearBudgetCodeReducer,
    copYearCostCategory: copYearCostCategoryReducer,
    copYearIntervention: copYearInterventionReducer,
    copYear: copYearReducer,
    copYearSite: copYearSiteReducer,
    copYearOutlay: copYearOutlayReducer,
    costCategory: costCategoryReducer,
    district: districtReducer,
    intervention: interventionReducer,
    milestone: milestoneReducer,
    project: projectReducer,
    quarter: quarterReducer,
    siteType: siteTypeReducer,
    site: siteReducer,
    surgeActivity: surgeActivityReducer,
    zone: zoneReducer,
    target: targetReducer,
    annualTarget: annualTargetReducer,
    region: regionReducer,
    outlay: outlayReducer,
    outlayDash: outlayDashReducer,
    milestoneDash: milestoneDashReducer,
    runner: runnerReducer,
    includeRunner: includeRunnerReducer,
    regionRunnerPeriod: regionRunnerPeriodReducer,
    runnerComponentHistory: runnerComponentHistoryReducer,
    runnerComponent: runnerComponentReducer,
    runnerHistory: runnerHistoryReducer,
    runnerStatus: runnerStatusReducer,
    runnerPeriodComponent: runnerPeriodComponentReducer,
    runnerPriodHistory: runnerPeriodHistoryReducer,
    runnerPeriodStatus: runnerPeriodStatusReducer,
    runnerPeriod: runnerPeriodReducer,
    strategy: strategyReducer,
    transactionCode: transactionCodeReducer,
    userCoordination: userCoordinationReducer,
    week: weekReducer,
    projectSite: projectSiteReducer,
    dailyData: dailyDataReducer,
    runnerArchive: runnerArchiveReducer,
}

export { reducers as budgetingReducers }
