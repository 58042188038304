import React from 'react'
import { AiFillCar } from 'react-icons/ai'
import { BsFillPeopleFill } from 'react-icons/bs'
import { TbTruckLoading, TbFileDollar } from 'react-icons/tb'
import { format } from '../../utils/format'
import DashboardCard from '../../components/dashboard/card.component'
import { Row } from 'antd'
import { IBudgetSummary } from '../../models/budgeting/budget-dashboard.model'
import { access } from 'fs'

type Props = {
    annualBudgets: IBudgetSummary[]
    componentBudgets: IBudgetSummary[]
    regionExpenditures: IBudgetSummary[]
    totalExpenditures: IBudgetSummary[]
}

export default function HomeDashboardQuickinfo({
    annualBudgets,
    componentBudgets,
    regionExpenditures,
    totalExpenditures,
}: Props) {
    const annualBudgetAmount = annualBudgets.reduce(
        (acc, curr) => acc + curr.amountUsd,
        0
    )
    const componentBudgetAmount = componentBudgets.reduce(
        (acc, curr) => acc + curr.amountUsd,
        0
    )
    const regionExpenditureAmount = regionExpenditures.reduce(
        (acc, curr) => acc + curr.amountUsd,
        0
    )
    const totalExpenditureAmount = totalExpenditures.reduce(
        (acc, curr) => acc + curr.amountUsd,
        0
    )

    const cards = [
        {
            id: 1,
            title: 'Annual Budget',
            amount: format.number(annualBudgetAmount),
            icon: (
                <BsFillPeopleFill
                    size={20}
                    color="#3362a8"
                    style={{ marginBottom: -10, marginRight: -10 }}
                />
            ),
        },
        {
            id: 2,
            title: 'Component Budget',
            amount: format.number(componentBudgetAmount),
            icon: (
                <AiFillCar
                    size={20}
                    color="#3362a8"
                    style={{ marginBottom: -10, marginRight: -10 }}
                />
            ),
        },
        {
            id: 3,
            title: 'Region Expenditure',
            amount: format.number(regionExpenditureAmount),
            icon: (
                <TbTruckLoading
                    size={18}
                    color="#3362a8"
                    style={{ marginBottom: -10, marginRight: -10 }}
                />
            ),
        },
        {
            id: 4,
            title: 'Total Expenditure',
            amount: format.number(totalExpenditureAmount),
            icon: (
                <TbFileDollar
                    size={20}
                    color="#3362a8"
                    style={{ marginBottom: -10, marginRight: -10 }}
                />
            ),
        },
    ]

    return (
        <Row gutter={[16, 16]} style={{ marginTop: 20, marginBottom: 25 }}>
            {cards.map((card, index) => (
                <DashboardCard
                    key={index}
                    title={card.title}
                    amount={card.amount}
                    icon={card.icon}
                />
            ))}
        </Row>
    )
}
