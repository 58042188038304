import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as budgetDashboardModel from '../../models/budgeting/budget-dashboard.model'
import budgetDashboardService from '../../services/budgeting/budget-dashboard.service'
import { UpdateMode } from '../../models/update-mode.enum'
import { IBudgetSummaryOptions } from '../../models/budgeting/budget-dashboard.model'

export const initialState: budgetDashboardModel.IBudgetDashboardState = {
    budgetDashboard: budgetDashboardModel.emptyBudgetDashboard,
    errors: '',
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchBudgetDashboardAsync = createAsyncThunk<
    budgetDashboardModel.IBudgetDashboard,
    IBudgetSummaryOptions
>('budgetDashboard/fetchBudgetDashboardAsync', async (options, thunkApi) => {
    try {
        return await budgetDashboardService.details(options)
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const budgetDashboardSlice = createSlice({
    name: 'budgetDashboard',
    initialState,
    reducers: {
        fetchBudgetDashboardRequest: (state) => {
            state.isLoading = true
        },
        fetchBudgetDashboardSuccess: (
            state,
            action: PayloadAction<budgetDashboardModel.IBudgetDashboard>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.budgetDashboard = action.payload
        },
        fetchBudgetDashboardError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBudgetDashboardAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchBudgetDashboardAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.budgetDashboard = action.payload
        })
        builder.addCase(fetchBudgetDashboardAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchBudgetDashboardRequest,
    fetchBudgetDashboardSuccess,
    fetchBudgetDashboardError,
} = budgetDashboardSlice.actions

const reducer = budgetDashboardSlice.reducer

export { reducer as budgetDashboardReducer }
