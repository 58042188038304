import { appRoutes } from '../../../constants/app-route'
import { IRoute } from '../../../models/common/route.model'
import OutlayVsTargetDashboardPage from '../../../pages/dashboard/outlay-target-dashboard.page'
import SiteBASiteReport from '../../../pages/reports/site-reports/site-ba-site.report'
import SiteBudgetExecutionReport from '../../../pages/reports/site-reports/site-budget-execution.report'
import SiteCdvErTemplateReport from '../../../pages/reports/site-reports/site-cdv-er-template.report'
import SiteCostCategoryReport from '../../../pages/reports/site-reports/site-cost-category.report'
import SiteCostedWorkPlanReport from '../../../pages/reports/site-reports/site-cost-work-plan.report'
import SiteInterventionReport from '../../../pages/reports/site-reports/site-intervention.report'
import SurgeBASiteReport from '../../../pages/reports/surge/surge-ba-site.report'

export const siteReportRoutes: IRoute[] = [
    /**
     * Site report routes
     */
    {
        path: appRoutes.SITE_REPORT.BA_COST_CATEGORY,
        private: true,
        exact: true,
        component: SiteCostCategoryReport,
    },
    {
        path: appRoutes.SITE_REPORT.BA_INTERVENTION,
        private: true,
        exact: true,
        component: SiteInterventionReport,
    },
    {
        path: appRoutes.SITE_REPORT.BA_SITE,
        private: true,
        exact: true,
        component: SiteBASiteReport,
    },
    {
        path: appRoutes.SITE_REPORT.BUDGET_EXECUTION,
        private: true,
        exact: true,
        component: SiteBudgetExecutionReport,
    },
    {
        path: appRoutes.SITE_REPORT.CDV_ACC_TEMPLATE,
        private: true,
        exact: true,
        component: OutlayVsTargetDashboardPage,
    },
    {
        path: appRoutes.SITE_REPORT.CDV_ER_TEMPLATE,
        private: true,
        exact: true,
        component: SiteCdvErTemplateReport,
    },
    {
        path: appRoutes.SITE_REPORT.COSTED_WORK_PLAN,
        private: true,
        exact: true,
        component: SiteCostedWorkPlanReport,
    },
]
