import {
    HomeOutlined,
    AppstoreOutlined,
    PieChartOutlined,
    BarChartOutlined,
    DotChartOutlined,
    SettingOutlined,
    MessageOutlined,
} from '@ant-design/icons'
import { ReactNode } from 'react'
import { FiActivity, FiRss, FiTrendingUp } from 'react-icons/fi'
import { appRoutes } from '../../constants/app-route'
import { activitySubmenus } from './sidebar-submenus/activity-submenus'
import { dashboardSubmenus } from './sidebar-submenus/dashboard-submenus'
import { laboratoryActivitySubmenus } from './sidebar-submenus/laboratory-activity-submenus'
import { programReportSubmenus } from './sidebar-submenus/program-report.submenus'
import { runnerSubmenus } from './sidebar-submenus/runner-submenus'
import { settingSubmenus } from './sidebar-submenus/setting.submenus'
import { siteReportSubmenus } from './sidebar-submenus/site-report.submenus'
import { surgeActivitySubmenus } from './sidebar-submenus/surge-activity-submenus'
import { zoneReportSubmenus } from './sidebar-submenus/zone-report.submenus'
import { faqsSubmenus } from './sidebar-submenus/faqs.submenus'
import { surgeReportSubmenus } from './sidebar-submenus/surge-report.submenus'
export interface ISideBarLink {
    app: string
    code: string
    description: string
    route: string
    icon?: ReactNode
    dropDownMenus: ISideBarLink[]
    dropDown: boolean
    parent: string
}

export const sidebarData: ISideBarLink[] = [
    {
        app: '01' /* dashbaord */,
        code: 'H01',
        description: 'Home',
        route: '/home',
        dropDown: false,
        dropDownMenus: [],
        icon: <HomeOutlined />,
        parent: '',
    },
    {
        app: '01' /* home */,
        code: 'A',
        description: 'Home',
        route: '/',
        dropDown: false,
        dropDownMenus: [],
        icon: <HomeOutlined />,
        parent: '',
    },
    {
        app: '01' /* dashbaord */,
        code: 'B',
        description: 'Dashboards',
        route: appRoutes.DASHBOARD,
        dropDown: true,
        dropDownMenus: [...sorted(dashboardSubmenus)],
        icon: <AppstoreOutlined />,
        parent: '',
    },

    {
        app: '01' /* activity */,
        code: 'C',
        description: 'Activities',
        route: '/site-activitiies-summary',
        dropDown: true,
        dropDownMenus: [...sorted(activitySubmenus)],
        icon: <FiActivity />,
        parent: '',
    },
    {
        app: '01',
        code: 'J',
        description: 'Surge Activities',
        route: '/surge',
        dropDown: true,
        dropDownMenus: [...sorted(surgeActivitySubmenus)],
        icon: <SettingOutlined />,
        parent: '',
    },
    {
        app: '01' /* laboratory activities */,
        code: 'D',
        description: 'Laboratory Activities',
        route: '/laboratory',
        dropDown: true,
        dropDownMenus: [...sorted(laboratoryActivitySubmenus)],
        icon: <FiTrendingUp />,
        parent: '',
    },
    {
        app: '01' /* runner*/,
        code: 'E',
        description: 'Runner',
        route: '/runner-menu',
        dropDown: true,
        dropDownMenus: [...sorted(runnerSubmenus)],
        icon: <FiRss />,
        parent: '',
    },
    {
        app: '01',
        code: 'L',
        description: 'Surge Reports',
        route: '/surge-reports',
        dropDown: true,
        dropDownMenus: [...sorted(surgeReportSubmenus)],
        icon: <BarChartOutlined />,
        parent: '',
    },
    {
        app: '01',
        code: 'F',
        description: 'Site Reports',
        route: '/site-reports',
        dropDown: true,
        dropDownMenus: [...sorted(siteReportSubmenus)],
        icon: <BarChartOutlined />,
        parent: '',
    },
    {
        app: '01',
        code: 'G',
        description: 'Program Reports',
        route: '/program-reports',
        dropDown: true,
        dropDownMenus: [...sorted(programReportSubmenus)],
        icon: <DotChartOutlined />,
        parent: '',
    },
    {
        app: '01',
        code: 'H',
        description: 'Zone Reports',
        route: '/zone-reports',
        dropDown: true,
        dropDownMenus: [...sorted(zoneReportSubmenus)],
        icon: <PieChartOutlined />,
        parent: '',
    },

    {
        app: '01',
        code: 'K',
        description: 'FAQs',
        route: '/faqs',
        dropDown: true,
        dropDownMenus: [...sorted(faqsSubmenus)],
        icon: <MessageOutlined />,
        parent: '',
    },

    {
        app: '01',
        code: 'I',
        description: 'Settings',
        route: '/settings',
        dropDown: true,
        dropDownMenus: [...sorted(settingSubmenus)],
        icon: <SettingOutlined />,
        parent: '',
    },
]

function sorted(arr: ISideBarLink[]) {
    return arr.sort((a, b) => (a.code > b.code ? 1 : -1))
}
