import React, { useState } from 'react'
// import Image from '../components/partner-card/logos/background.jpg'
import { Layout } from 'antd'
import { MenuFoldOutlined } from '@ant-design/icons'
import { SidebarNavigation } from '../components/welcome/sidebar-nav.component'
import { NavigationMenu } from '../components/welcome/navigation-menu.component'
import './welcome.style.scss'
import { signinRedirect } from '../services/shared/auth/auth.service'
import { Link } from 'react-router-dom'
import PartnerCard from '../components/partner-card/partner-card.component'

const { Header } = Layout

const WelcomePage: React.FC = () => {
    const [show, setShow] = useState(false)
    const [, setLoading] = useState(false)

    const showDrawer = () => {
        setShow(true)
    }
    const onClose = () => {
        setShow(false)
    }

    function login() {
        setLoading(true)
        signinRedirect()
    }

    return (
        <Layout className="layout">
            <SidebarNavigation onClose={onClose} visible={show} login={login} />
            <Header
                style={{
                    position: 'fixed',
                    zIndex: 1,
                    width: '100%',
                    background: '#12638F',
                }}
                className={'page__header'}
            >
                <div className="logo">
                    <p
                        style={{
                            fontSize: '20px',
                            marginTop: '1rem',
                            fontWeight: 'bold',
                            color: 'white',
                        }}
                    >
                        DAMA FINANCE
                    </p>
                </div>

                <MenuFoldOutlined
                    onClick={showDrawer}
                    style={{ color: '#572321', fontSize: '18px' }}
                    className="trigger toggle"
                />
                <div className="menu">
                    <NavigationMenu />
                </div>
            </Header>
            {/*  carousel */}

            <div className="my-5 text-center hero my-md-0 text-md-left">
                <div className="container my-3 my-md-0">
                    <div className="mb-4 hero__title font-weight-bold">
                        <div className="mb-4 hero__title--main">
                            <img
                                src="/images/dama-title.png"
                                className="img-fluid"
                                alt="HIV Free Project"
                            />
                        </div>
                        <div className="hero__title--sub">
                            <img
                                src="/images/dama-subtitle.png"
                                className="img-fluid"
                                alt="West, North West and West regions"
                            />
                        </div>
                    </div>
                    <div className="py-3 hero__content">
                        <p className="text-center project__title">
                            Expanding coverage and improving on the Quality of
                            Facility and Community-Based{' '}
                            <abbr
                                title="Human Immunodeficiency Virus"
                                className="text-decoration-none text-dark"
                            >
                                HIV
                            </abbr>{' '}
                            Programs in the North West, South West and West Regions
                            of Cameroon under the President's Emergency Plan for{' '}
                            <abbr
                                title="Acquired Immune Deficiency Syndrome"
                                className="text-decoration-none text-dark"
                            >
                                AIDS
                            </abbr>{' '}
                            Relief(PEPFAR).
                        </p>
                        <PartnerCard />
                        <Link
                            to="#"
                            onClick={login}
                            className="py-3 mt-3 btn btn-sm btn-lg btn-primary btn-block px-lg-5 d-md-none login-btn"
                        >
                            Sign in
                        </Link>
                    </div>
                </div>
            </div>
            {/* hero section end */}

            {/* features section */}
            <footer className="py-2 footer__landing">
                <div className="container">
                    <p className="mb-0 text-center text-white">
                        {' '}
                        &copy;{' '}
                        <abbr
                            title="Cameroon Baptist Convention"
                            className="text-decoration-none"
                        >
                            {new Date().getFullYear()} CBC
                        </abbr>{' '}
                        Health Service
                    </p>
                </div>
            </footer>
            {/* single feature end */}
        </Layout>
    )
}

export default WelcomePage
