import { Row, Segmented } from 'antd'
import { useEffect, useState } from 'react'
import PageBreadCrumbs from '../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { SegmentedValue } from 'antd/lib/segmented'
import { t } from 'i18next'
import { useLocation, useHistory } from 'react-router-dom'
import { useBudgetDashboard } from '../../hooks/budgeting/budget-dashboard.hook'

type Props = {
    filterField: string
    options: { label: string; value: string }[]
}

export default function HomeDashboardFilter({ filterField, options }: Props) {
    const location = useLocation()
    const history = useHistory()

    const { reloadBudgetDashboards } = useBudgetDashboard()
    // Parse the current query parameters
    const searchParams = new URLSearchParams(location.search)
    const currentFilter = searchParams.get(filterField) || 'week'

    const [value, setValue] = useState<SegmentedValue>(currentFilter)

    function handleClick(value: SegmentedValue) {
        // Update the query parameters
        searchParams.set(filterField, value.toString())
        if (searchParams.get('page')) searchParams.set('page', 'week')

        // Push the updated query string to the history
        history.push({ search: searchParams.toString() })
    }

    const handleChange = (value: SegmentedValue) => {
        setValue(value)
        handleClick(value)
    }

    useEffect(() => {
        // Set the default value when the page loads if not already set
        if (!searchParams.has(filterField)) {
            const defaultOption = options.at(0)
            if (defaultOption) {
                searchParams.set(filterField, defaultOption.value)
            }
            window.history.replaceState({}, '', `?${searchParams.toString()}`)
        }
    }, [filterField, options, searchParams]) // Run only when filterField or options change

    useEffect(() => {
        // React to query param changes
        reloadBudgetDashboards(currentFilter)
    }, [location.search, filterField]) // Trigger effect when location.search or filterField changes

    return (
        <Row
            justify={'space-between'}
            align={'middle'}
            style={{ margin: '0.5rem 0' }}
        >
            <div>
                <PageBreadCrumbs items={[t('common:breadCrumb.dashboard')]} />
            </div>
            <div>
                <Segmented
                    options={options}
                    value={value}
                    color="#3362a8"
                    style={{ background: '#b0d2ff', color: '#000000' }}
                    onChange={handleChange}
                />
            </div>
        </Row>
    )
}
