import React from 'react'
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { Card, Col, Row, Statistic } from 'antd'
import 'antd/dist/antd.css' // Import Ant Design styles
import { IBudgetSummary } from '../../models/budgeting/budget-dashboard.model'

// Define the type for the data
type BudgetItem = {
    code: string
    description: string
    amount: number
}

const data: IBudgetSummary[] = [
    { code: '01', description: 'HTXS', amountUsd: 2193242, amountCfa: 1200000000 },
    { code: '02', description: 'HVCT', amountUsd: 54000, amountCfa: 1200000000 },
    { code: '03', description: 'MTCT', amountUsd: 175200, amountCfa: 1200000000 },
    { code: '04', description: 'PDTXS', amountUsd: 209320, amountCfa: 1200000000 },
    { code: '05', description: 'VL', amountUsd: 13686, amountCfa: 1200000000 },
    { code: '06', description: 'HIV/TB', amountUsd: 30090, amountCfa: 1200000000 },
    { code: '07', description: 'PrEP', amountUsd: 31470, amountCfa: 1200000000 },
]

// Colors for the pie slices
const COLORS = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#AF19FF',
    '#FF4560',
    '#775DD0',
    '#00E396',
    '#FEB019',
    '#FF4560',
    '#2E93fA',
    '#66DA26',
    '#546E7A',
    '#E91E63',
    '#FF9800',
    '#33B2DF',
    '#546E7A',
    '#D4526E',
]

interface BudgetPieChartProps {
    data: IBudgetSummary[]
    title?: string
    colProps?: object // Props for the Col component
}

const renderCustomLabel = ({ description, amountUsd }: IBudgetSummary) =>
    `${description}: ${amountUsd.toLocaleString()}`

const BudgetPieChart: React.FC<BudgetPieChartProps> = ({
    data,
    title = 'Budget Distribution',
    colProps = { xs: 24, md: 24, lg: 12 },
}) => {
    return (
        <Col {...colProps}>
            <Card size="small" title={title} bodyStyle={{ padding: 0 }}>
                <ResponsiveContainer width="100%" height={350}>
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey="amountUsd"
                            nameKey="description"
                            cx="50%"
                            cy="50%"
                            outerRadius={125}
                            fill="#8884d8"
                            label={renderCustomLabel}
                        >
                            {data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                />
                            ))}
                        </Pie>
                        <Tooltip
                            formatter={(value: number) => value.toLocaleString()}
                            contentStyle={{ fontSize: '15px' }}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </Card>
        </Col>
    )
}

type BudgetPieChartDashboardProps = {
    budget: IBudgetSummary[]
    expenditure: IBudgetSummary[]
}

const BudgetPieChartDashboard: React.FC<BudgetPieChartDashboardProps> = ({
    budget,
    expenditure,
}) => {
    return (
        <Row gutter={[16, 16]} style={{ marginTop: 20, marginBottom: 25 }}>
            <BudgetPieChart data={budget} title="Annual Budget" />
            <BudgetPieChart data={expenditure} title="Expenditure" />
        </Row>
    )
}

export default BudgetPieChartDashboard
