import { Col, Card, Statistic } from 'antd'
import { ReactNode } from 'react'
import { IBudgetCard } from '../../models/budgeting/outlay-card.model'
import { format } from '../../utils/format'

type BudgetCardProps = {
    title: string
    total?: number
    items: IBudgetCard[]
    icon?: ReactNode
    colProps?: {
        xs?: number
        md?: number
        lg?: number
    }
    columnProps?: {
        a?: number
        b?: number
        c?: number
    }
}

export default function BudgetCard({
    title,
    items,
    icon,
    colProps = { xs: 24, md: 24, lg: 12 },
    columnProps = { a: 1, b: 1, c: 1 },
}: BudgetCardProps) {
    const totalAmount = items.reduce((acc, item) => acc + item.amountUsd, 0)
    const { a, b, c } = columnProps
    return (
        <Col {...colProps}>
            <Card size="small" title={title} bodyStyle={{ padding: 0 }}>
                {items.map((item, index) => (
                    <div
                        className="card__column"
                        style={{
                            display: 'flex',
                            padding: '8px',
                            fontSize: '.78rem',
                            backgroundColor:
                                index % 2 === 0 ? 'rgb(234, 241, 246)' : '',
                            fontWeight: '500',
                        }}
                        key={item.code + index}
                    >
                        <span
                            style={{
                                flexGrow: a,
                                flexBasis: 0, // Ensures flexGrow takes precedence
                                textAlign: 'left',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                minWidth: 0, // Prevents content from forcing width
                            }}
                        >
                            {item.description}
                        </span>
                        <span
                            style={{
                                flexGrow: b,
                                textAlign: 'right',
                                minWidth: 0,
                                flexBasis: 0,
                            }}
                        >
                            {format.number(item.amountUsd)}
                        </span>
                        <span
                            style={{
                                flexGrow: c,
                                textAlign: 'right',
                                minWidth: 0,
                                flexBasis: 0,
                            }}
                        >
                            {format.number(item.amountCfa)}
                        </span>
                    </div>
                ))}
            </Card>
        </Col>
    )
}
