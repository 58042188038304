import { AiOutlinePieChart } from 'react-icons/ai'
import { appRoutes } from '../../../constants/app-route'
import { ISideBarLink } from '../sidebar.data'

export const surgeReportSubmenus: ISideBarLink[] = [
    {
        app: '01',
        code: 'L00',
        description: 'BA Cost Category',
        route: appRoutes.SURGE_REPORTS.BA_COST_CATEGORY,
        dropDown: false,
        dropDownMenus: [],
        icon: <AiOutlinePieChart />,
        parent: 'L',
    },
]
