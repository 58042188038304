import { Row } from 'antd'
import { useOutlayDash } from '../../hooks/budgeting/outlay-dash.hook'
import { ThunderboltTwoTone } from '@ant-design/icons'

import BudgetCard from './budget-card.component'
import { IBudgetSummary } from '../../models/budgeting/budget-dashboard.model'

const cardOneIcon = (
    <ThunderboltTwoTone
        style={{
            fontSize: '2rem',
            fill: 'red',
            color: 'red',
        }}
    />
)

type Props = {
    interventions: IBudgetSummary[]
    costCategories: IBudgetSummary[]
}

export default function HomeDashboardBudgetIntervention({
    interventions,
    costCategories,
}: Props) {
    const { totalTargetComponentOne } = useOutlayDash()

    const budgetItemsIntervention: IBudgetSummary[] = [
        {
            code: '01',
            description: 'IM Program Management',
            amountUsd: 36000,
            amountCfa: 21600000,
        },
        {
            code: '02',
            description: 'Health Management Information Systems (HMIS)',
            amountUsd: 460752,
            amountCfa: 276451200,
        },
        {
            code: '03',
            description: 'HIV Clinical Services NSD Children',
            amountUsd: 105500,
            amountCfa: 63300000,
        },
        {
            code: '04',
            description: 'HIV Clinical Services NSD Non-Targeted Populations',
            amountUsd: 1395180,
            amountCfa: 837108000,
        },
        {
            code: '05',
            description: 'HIV Clinical Services SD AGYW',
            amountUsd: 8820,
            amountCfa: 5292000,
        },
        {
            code: '06',
            description: 'HIV Clinical Services SD Children',
            amountUsd: 103820,
            amountCfa: 62292000,
        },
        {
            code: '08',
            description: 'HIV Clinical Services SD Non-Targeted Populations',
            amountUsd: 301310,
            amountCfa: 180786000,
        },
        {
            code: '09',
            description: 'HIV Clinical Services SD Pregnant & Breastfeeding Women',
            amountUsd: 166380,
            amountCfa: 99828000,
        },
        {
            code: '10',
            description: 'HIV Laboratory Services SD Non-Targeted Populations',
            amountUsd: 13686,
            amountCfa: 8211600,
        },
        {
            code: '11',
            description: 'Facility-based testing SD Non-Targeted Populations',
            amountUsd: 17700,
            amountCfa: 10620000,
        },
        {
            code: '12',
            description: 'PrEP NSD Non-Targeted Populations',
            amountUsd: 19500,
            amountCfa: 11700000,
        },
        {
            code: '13',
            description: 'PrEP SD Key Populations',
            amountUsd: 11970,
            amountCfa: 7182000,
        },
        {
            code: '14',
            description: 'HIV/TB  SD Non-Targeted Populations',
            amountUsd: 30090,
            amountCfa: 18054000,
        },
        {
            code: '17',
            description: 'Community-based testing SD Non-Targeted Populations',
            amountUsd: 36300,
            amountCfa: 21780000,
        },
    ]

    var columnProps = { a: 3, b: 1, c: 1 }

    return (
        <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
            <BudgetCard
                title={'Budget by Invervention'}
                items={interventions}
                icon={cardOneIcon}
                columnProps={columnProps}
            />

            <BudgetCard
                title={'Budget by Cost Category'}
                items={costCategories}
                icon={cardOneIcon}
                columnProps={columnProps}
            />
        </Row>
    )
}
