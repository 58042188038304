import { IBranch } from '../../../models/common/branch.model'
import {
    emptyCurrentBranch,
    ICurrentBranch,
} from '../../../models/common/current-branch.model'
import { fetchBranchesSuccess } from '../../common/branch/branch.slice'
import { fetchServerStatusesSuccess } from '../../common/server-status/server-status.slice'
import { fetchCurrentBranchSuccess } from '../../common/current-branch/current-branch.slice'
import { fetchCurrentUserSuccess } from '../../common/current-user/current-user.slice'
import { fetchDateGenerationsSuccess } from '../../common/date-generation/date-generation.slice'
import { appThunk } from '../../root-reducer'
import {
    fetchSessionBatchError,
    fetchSessionBatchRequest,
    fetchSessionBatchSuccess,
} from './session-batch.slice'
import { fetchBranchStationsAsync } from '../../common/branch-station/branch-station.slice'
import { fetchDialogMessagesAsync } from '../../common/dialog-message/dialog-message.slice'
import { sessionBatchService } from '../../../services/batch/session-batch.service'
import { ISessionBatch } from '../../../models/batch/session-batch.model'
import { fetchUsersAsync } from '../user/user.slice'
import { fetchComponentsAsync } from '../../budgeting/component.slice'
import { fetchRunnersSuccess, setActiveRunner } from '../../budgeting/runner.slice'
import { fetchRunnerComponentsSuccess } from '../../budgeting/runner-component.slice'
import { emptyRunner, IRunner } from '../../../models/budgeting/runner.model'
import { fetchRegionsAsync } from '../../budgeting/region.slice'
import { fetchActivityPlansAsync } from '../../budgeting/activity-plan.slice'
import { fetchSitesAsync } from '../../budgeting/site.slice'
import { fetchUserMenusSuccess } from '../../common/user-menu/user-menu.slice'
import {
    emptyCurrentUser,
    ICurrentUser,
} from '../../../models/common/current-user.model'
import { fetchWeeksAsync } from '../../budgeting/week.slice'
import { fetchOutlayDashAsync } from '../../budgeting/outlay-dash.slice'
import { fetchProjectSitesAsync } from '../../budgeting/project-site.slice'
import { fetchRunnerPeriodHistoriesAsync } from '../../budgeting/runner-period-history.slice'
import { fetchRunnerHistoriesAsync } from '../../budgeting/runner-history.slice'
import { fetchStrategiesAsync } from '../../budgeting/strategy.slice'
import { fetchMonthNamesAsync } from '../../common/month-name/month-name.slice'
import { fetchUserCoordinationAsync } from '../../budgeting/user-coordination.slice'
import { fetchUserBranchesAsync } from '../../common/user-branch/user-branch.slice'
import { ActivityPlanParamType } from '../../../models/budgeting/activity-plan.model'
import { IRunnerArchiveOptions } from '../../../models/budgeting/runner-archive.model'
import { fetchRunnerArchivesAsync } from '../../budgeting/runner-archive.slice'
import { IRunnerComponent } from '../../../models/budgeting/runner-component.model'
import { fetchSurgeActivitiesAsync } from '../../budgeting/surge-activity.slice'
import { fetchBudgetDashboardAsync } from '../../budgeting/budget-dashboard.slice'

const fetchRemainingInitialDataAsync =
    (copYear: string, activityPlanParams: ActivityPlanParamType): appThunk =>
    async (dispatch) => {
        dispatch(fetchBranchStationsAsync())
        dispatch(fetchComponentsAsync())
        dispatch(fetchDialogMessagesAsync())
        dispatch(fetchRegionsAsync())
        dispatch(fetchUsersAsync())
        dispatch(fetchActivityPlansAsync(activityPlanParams))
        dispatch(fetchProjectSitesAsync())
        dispatch(fetchWeeksAsync(copYear))
        dispatch(fetchRunnerPeriodHistoriesAsync())
        dispatch(fetchRunnerHistoriesAsync())
        dispatch(fetchStrategiesAsync())
        dispatch(fetchMonthNamesAsync())
        dispatch(fetchUserCoordinationAsync())
        dispatch(fetchUserBranchesAsync())
        dispatch(fetchSurgeActivitiesAsync())
    }

const getCurrentBranch = (
    allBranches: IBranch[],
    activeBranchCode: string
): ICurrentBranch => {
    if (allBranches) {
        var branch = allBranches.find((b) => b.code === activeBranchCode)

        return branch as ICurrentBranch
    }
    return {
        ...emptyCurrentBranch,
        code: activeBranchCode,
    }
}

const getRunner = (runners: IRunner[], branch: ICurrentBranch): IRunner => {
    if (runners) {
        const runner = runners.find(
            (b) => b.region === branch.region && b.project === branch.project
        )

        return runner as IRunner
    }
    return emptyRunner
}

export const fetchInitialDataAsync = (): appThunk => async (dispatch) => {
    try {
        dispatch(fetchSessionBatchRequest())

        sessionBatchService.getBatch().then((batch: ISessionBatch) => {
            const currentBranch = getCurrentBranch(batch.branches, batch.branch)
            const runner = getRunner(batch.runners, currentBranch)
            const runnerComponent = batch.runnerComponents.find(
                (item) => item.runner === runner.code
            ) as IRunnerComponent

            dispatch(fetchCurrentBranchSuccess(currentBranch))
            dispatch(setActiveRunner(runner))
            const currentUser: ICurrentUser = {
                ...emptyCurrentUser,
                code: batch.userCode,
            }
            const activityPlanParams = {
                copYear: runner.copYear,
                component: runnerComponent.component,
                project: runnerComponent.project,
                tenant: runner.tenant,
            }

            dispatch(fetchCurrentUserSuccess(currentUser))
            dispatch(fetchRunnersSuccess(batch.runners))
            dispatch(fetchRunnerComponentsSuccess(batch.runnerComponents))
            dispatch(fetchBranchesSuccess(batch.branches))
            dispatch(fetchServerStatusesSuccess(batch.serverStatuses))
            dispatch(fetchDateGenerationsSuccess(batch.dateGenerations))
            dispatch(fetchUserMenusSuccess(batch.userMenus))

            console.log('runner: ', runner)
            dispatch(fetchWeeksAsync(runner.copYear))
            var runnerOptions: IRunnerArchiveOptions = {
                region: runner.region,
                project: runner.project,
                copYear: runner.copYear,
            }

            dispatch(fetchRunnerArchivesAsync(runnerOptions))
            dispatch(fetchActivityPlansAsync(activityPlanParams))

            dispatch(fetchSessionBatchSuccess())
            dispatch(fetchOutlayDashAsync(currentBranch.region))
            dispatch(fetchSitesAsync(currentBranch.region))
            dispatch(
                fetchBudgetDashboardAsync({
                    project: currentBranch.project,
                    component: runnerComponent.component,
                    period: 'week',
                    region: currentBranch.region,
                })
            )
            dispatch(
                fetchRemainingInitialDataAsync(runner.copYear, activityPlanParams)
            )
        })
    } catch (error: any) {
        dispatch(fetchSessionBatchError(error?.message))
    }
}

export const fetchInitialDataBranchAsync =
    (branch: string): appThunk =>
    async (dispatch) => {
        try {
            dispatch(fetchSessionBatchRequest())

            sessionBatchService
                .getBatchBranch(branch)
                .then((batch: ISessionBatch) => {
                    const currentBranch = getCurrentBranch(batch.branches, branch)
                    const runner = getRunner(batch.runners, currentBranch)

                    dispatch(fetchCurrentBranchSuccess(currentBranch))
                    dispatch(setActiveRunner(runner))
                    const currentUser: ICurrentUser = {
                        ...emptyCurrentUser,
                        code: batch.userCode,
                    }

                    const runnerComponent = batch.runnerComponents.find(
                        (item) => item.runner === runner.code
                    ) as IRunnerComponent

                    const activityPlanParams = {
                        copYear: runner.copYear,
                        component: runnerComponent.component,
                        project: runnerComponent.project,
                        tenant: runner.tenant,
                    }

                    dispatch(fetchCurrentUserSuccess(currentUser))
                    dispatch(fetchRunnersSuccess(batch.runners))
                    dispatch(fetchRunnerComponentsSuccess(batch.runnerComponents))
                    dispatch(fetchBranchesSuccess(batch.branches))
                    dispatch(fetchServerStatusesSuccess(batch.serverStatuses))
                    dispatch(fetchDateGenerationsSuccess(batch.dateGenerations))
                    dispatch(fetchUserMenusSuccess(batch.userMenus))
                    dispatch(fetchSessionBatchSuccess())

                    dispatch(fetchWeeksAsync(runner.copYear))
                    var options: IRunnerArchiveOptions = {
                        region: runner.region,
                        project: runner.project,
                        copYear: runner.copYear,
                    }
                    dispatch(fetchRunnerArchivesAsync(options))

                    dispatch(fetchActivityPlansAsync(activityPlanParams))

                    dispatch(fetchWeeksAsync(runner.copYear))

                    dispatch(fetchOutlayDashAsync(currentBranch.region))
                    dispatch(fetchSitesAsync(currentBranch.region))

                    dispatch(
                        fetchRemainingInitialDataAsync(
                            runner.copYear,
                            activityPlanParams
                        )
                    )
                })
        } catch (error: any) {
            dispatch(fetchSessionBatchError(error?.message))
        }
    }
