import { appRoutes } from '../../../constants/app-route'
import { IRoute } from '../../../models/common/route.model'
import SurgeBASiteReport from '../../../pages/reports/surge/surge-ba-site.report'

export const surgeReportRoutes: IRoute[] = [
    {
        path: appRoutes.SURGE_REPORTS.BA_COST_CATEGORY,
        private: true,
        exact: true,
        component: SurgeBASiteReport,
    },
]
