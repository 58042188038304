import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IBudgetSummaryOptions {
    project: string
    component: string
    period: string
    region: string
}

export interface IBudgetSummary {
    code: string
    description: string
    amountUsd: number
    amountCfa: number
}

export interface IBudgetDashboard {
    annualBudgets: IBudgetSummary[]
    componentBudgets: IBudgetSummary[]
    regionExpenditures: IBudgetSummary[]
    totalExpenditures: IBudgetSummary[]
    interventions: IBudgetSummary[]
    costCategories: IBudgetSummary[]
}

export const emptyBudgetDashboard: IBudgetDashboard = {
    annualBudgets: [],
    componentBudgets: [],
    regionExpenditures: [],
    totalExpenditures: [],
    interventions: [],
    costCategories: [],
}

export interface IBudgetDashboardState extends IBaseState {
    readonly budgetDashboard: IBudgetDashboard
}

export interface IBudgetDashboardResponse extends IResponseBase {
    data: IBudgetDashboard
}
