import { DatePicker, Button, Card, Col, Form, Row, Select, Space, Input } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { BiPrinter } from 'react-icons/bi'
import { useBudgetAnalysisContext } from '../../contexts/reports/report.context'
import { useCopYear } from '../../hooks/budgeting/cop-year.hook'
import { useRegion } from '../../hooks/budgeting/region.hook'
import { useRunner } from '../../hooks/budgeting/runner.hook'
import { useTransactionCode } from '../../hooks/budgeting/transaction-code.hook'
import { useConfiguration } from '../../hooks/configuration.hook'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../hooks/common/current-user/current-user.hook'
import { useCurrentBranch } from '../../hooks/common/current-branch/current-branch.hook'
import { useBudgetAnalysis } from '../../hooks/reports/budget-analysis.hook'
import { useUserBranch } from '../../hooks/common/user-branch/user-branch.hook'
import { useBranch } from '../../hooks/common/branch.hook'

type Props = {
    formRef: any
    isSurge?: boolean
}

const ReportFilterComponent: React.FunctionComponent<Props> = ({
    formRef,
    isSurge = false,
}) => {
    /* hooks */
    const [form] = Form.useForm()
    const [isPdfButton, setIsPdfButton] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingReport, setLoadingReport] = useState(false)

    const { branches } = useBranch()
    const { regions } = useRegion()
    const { copYear, copYears } = useCopYear()
    const { runner } = useRunner()
    const { Option } = Select
    const { configuration } = useConfiguration()
    const { transactionCodes } = useTransactionCode()
    const { currentUser } = useCurrentUser()
    const { currentBranch } = useCurrentBranch()
    const { userBranches } = useUserBranch()
    const { getBudgetAnalysisReport, getBudgetAnalysisData } = useBudgetAnalysis()
    const {
        setReportFile,
        setHasPdf,
        setHasReportData,
        setReportData,
        activityType,
    } = useBudgetAnalysisContext()
    const { t } = useTranslation()

    const userBranchCodes = userBranches.map((item) => item.branchCode)
    const userRegions = branches
        .filter((branch) => userBranchCodes.includes(branch.code))
        .map((branch) => branch.region)

    /* local state */
    const reportTypeOptions = [
        { value: '01', label: 'Site' },
        { value: '02', label: 'Above Site' },
        { value: '03', label: 'Combined' },
    ]

    const registeredRegions = ['07', '08', '09']

    // get all branhes that have this
    const filteredRegions = regions
        .filter((r) => registeredRegions.find((region) => region === r.code))
        .filter((region) => userRegions.includes(region.code))

    const isSiteReportType =
        configuration.activityType === '01' &&
        configuration.project === '01' &&
        configuration.reportType === '01'

    const isCommunityReportType =
        configuration.activityType === '01' &&
        configuration.project === '02' &&
        configuration.reportType === '01'

    const onFinish = async (values: any) => {
        const { startDate, endDate, ...rest } = values

        const formData = {
            ...rest,
            isSurge,
            copYear: copYears.at(0)!.code,
            region: currentBranch.region,
            startDate: startDate.format(),
            endDate: endDate.format(),
            project: currentBranch.project,
            tenant: currentBranch.tenant,
            user: currentUser.code,
            component: runner.component,
            activityType: activityType,
        }

        if (isPdfButton) {
            setLoadingReport(true)
            const reportFile = await getBudgetAnalysisReport(formData)
            setReportFile(reportFile)
            setHasPdf(true)
            setHasReportData(false)
            setLoading(false)
            setIsPdfButton(false)
            setLoadingReport(false)
        } else {
            setLoading(true)
            const data = await getBudgetAnalysisData(formData)
            console.log('data: ', data)
            setHasPdf(false)
            setHasReportData(true)
            setReportData(data)
            setLoading(false)
        }
    }

    const loadPdf = () => {
        setLoadingReport(true)
        setIsPdfButton(true)
        form.submit()
        // setIsPdfButton(false)
        setLoadingReport(false)
    }

    useEffect(() => {}, [isPdfButton])

    return (
        <>
            <Card size="small">
                <Form
                    name="report-filter-form"
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        startEndDate: [
                            moment(runner.startDate),
                            moment(runner.endDate),
                        ],
                    }}
                >
                    <Row gutter={[16, 16]}>
                        {/* region */}
                        <Col md={6} style={{ display: 'none' }}>
                            <Form.Item
                                label={t('common:siteReport.form.region')} //"Region"
                                labelCol={{ span: 24 }}
                                name="region"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Region is required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select region"
                                    onChange={(value) =>
                                        form.setFieldValue('region', value)
                                    }
                                    disabled={loading || loadingReport}
                                >
                                    {filteredRegions.map((region, index) => (
                                        <Option key={index} value={region.code}>
                                            {region.description}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* end region */}

                        {/* Transaction codes */}
                        <Col md={6}>
                            <Form.Item
                                label={t('common:siteReport.form.transactionCode')} //"Transaction code"
                                labelCol={{ span: 24 }}
                                name="tranCode"
                            >
                                <Select
                                    placeholder="Select transaction code"
                                    onChange={(value) =>
                                        form.setFieldValue('tranCode', value)
                                    }
                                    disabled={
                                        isSiteReportType ||
                                        isCommunityReportType ||
                                        loading ||
                                        loadingReport
                                    }
                                >
                                    {transactionCodes.map((tCode, index) => (
                                        <Option key={index} value={tCode.code}>
                                            {tCode.description}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* end transaction codes */}

                        {/* Report type */}
                        <Col md={6}>
                            <Form.Item
                                labelCol={{ span: 24 }}
                                name="reportType"
                                label={t('common:siteReport.form.reportType')} //"Report Type"
                            >
                                <Select
                                    placeholder="Select report type"
                                    onChange={(value) =>
                                        form.setFieldValue('reportType', value)
                                    }
                                    disabled={loading || loadingReport}
                                >
                                    {reportTypeOptions.map((rt, index) => (
                                        <Option key={index} value={rt.value}>
                                            {rt.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            {/* end copyear */}
                        </Col>
                        <Col md={6}>
                            <Form.Item
                                label={t('common:siteReport.form.startDate')} //"start date"
                                labelCol={{ span: 24 }}
                                name="startDate"
                                required
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    picker="date"
                                    format={'DD/MM/YYYY'}
                                    disabled={loading || loadingReport}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={6}>
                            <Form.Item
                                label={t('common:siteReport.form.endDate')} //"end date"
                                labelCol={{ span: 24 }}
                                name="endDate"
                                required
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    picker="date"
                                    format={'DD/MM/YYYY'}
                                    disabled={loading || loadingReport}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Space>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            disabled={loading}
                        >
                            {t('common:siteReport.mainPage.generateBtn')}
                        </Button>
                        <Button
                            style={{ background: '#fec601', color: '#000' }}
                            icon={<BiPrinter style={{ marginRight: '4px' }} />}
                            onClick={loadPdf}
                            loading={loadingReport}
                            disabled={loadingReport}
                        >
                            {t('common:siteReport.mainPage.loadReportBtn')}
                        </Button>
                    </Space>
                </Form>
            </Card>
        </>
    )
}

export default ReportFilterComponent
