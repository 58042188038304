import { Row } from 'antd'
import { useOutlayDash } from '../../hooks/budgeting/outlay-dash.hook'

import { ThunderboltTwoTone } from '@ant-design/icons'
import BudgetCard from './budget-card.component'
import { IBudgetSummary } from '../../models/budgeting/budget-dashboard.model'

const cardOneIcon = (
    <ThunderboltTwoTone
        style={{
            fontSize: '2rem',
            fill: 'red',
            color: 'red',
        }}
    />
)

type Props = {
    annualBudgets: IBudgetSummary[]
    componentBudgets: IBudgetSummary[]
    regionExpenditures: IBudgetSummary[]
    totalExpenditures: IBudgetSummary[]
}

export default function HomeDashboardBudgetBudgetCode({
    annualBudgets,
    componentBudgets,
    regionExpenditures,
    totalExpenditures,
}: Props) {
    const { totalTargetComponentOne } = useOutlayDash()

    const colProps = { xs: 24, md: 12, lg: 6 }

    return (
        <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
            <BudgetCard
                title={'Annual Budget'}
                total={totalTargetComponentOne}
                items={annualBudgets}
                icon={cardOneIcon}
                colProps={colProps}
            />

            <BudgetCard
                title={'Component Budget'}
                total={totalTargetComponentOne}
                items={componentBudgets}
                icon={cardOneIcon}
                colProps={colProps}
            />

            <BudgetCard
                title={'Region Expenditure'}
                total={totalTargetComponentOne}
                items={regionExpenditures}
                icon={cardOneIcon}
                colProps={colProps}
            />

            <BudgetCard
                title={'Total Expenditure'}
                total={totalTargetComponentOne}
                items={totalExpenditures}
                icon={cardOneIcon}
                colProps={colProps}
            />
        </Row>
    )
}
