import React from 'react'
import { Card, Col, Typography } from 'antd'
import { format } from '../../utils/format'
import { BsFillPeopleFill } from 'react-icons/bs'
import { AiFillCar } from 'react-icons/ai'
import { TbFileDollar, TbTruckLoading } from 'react-icons/tb'

type Props = {
    title: string
    amount: string
    icon?: React.ReactNode
    backgroundColor?: string // Optional prop for background color
}

export default function DashboardCard({
    title,
    amount,
    icon,
    backgroundColor = '#3362a8',
}: Props) {
    return (
        <Col xs={24} md={6} style={{ paddingLeft: '16px', paddingRight: '16px' }}>
            <Card
                bordered={false}
                style={{
                    border: `1px solid ${backgroundColor}`,
                    borderRadius: 10,
                }}
            >
                <Typography.Text style={{ marginBottom: 0 }}>
                    {title}
                </Typography.Text>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 5,
                    }}
                >
                    <div>
                        <Typography.Title
                            level={2}
                            style={{ marginBottom: 0, marginTop: 0 }}
                        >
                            {amount}
                        </Typography.Title>
                    </div>

                    <div>{icon}</div>
                </div>
            </Card>
        </Col>
    )
}
